import {
  Heading,
  IconCheck,
  IconShieldPaw,
  useLargeFromMediaQuery,
  IconShieldStar,
} from '@madpaws/design-system';
import getConfig from 'next/config';

import { ImageWithFallback } from '~/common/components/ImageWithFallback/ImageWithFallback';

import styles from './Trust.module.css';

import type { ReactElement } from 'react';

const { publicRuntimeConfig } = getConfig();

/*
 * This HTML ID-s are used to find the elements
 * and this is required to inject CSS from ReactNative app WebView
 * TODO(WEB-2258): Rename this variable to a more meaningful name
 */
const TRUST_HTML_ID = 'Explore_more_product_services';

export const Trust = (): ReactElement => {
  const isLargeFromViewport = useLargeFromMediaQuery();

  const trustBuilders = [
    'All sitters have their identity verified, and can choose to add police checks',
    'Every booking is covered by Accident Cover',
    'Access to our customer support team',
  ];

  const title = isLargeFromViewport
    ? 'Book with peace of mind'
    : 'Sitters you can trust and your pets will love';

  return (
    <section className={styles.root} id={TRUST_HTML_ID}>
      <div className={styles.contentWrapper}>
        <div className={styles.headingWrapper}>
          {isLargeFromViewport ? (
            <IconShieldPaw size="medium" />
          ) : (
            <IconShieldStar size="small2x" />
          )}
          <Heading
            alignment="centerAlign"
            fontFamily="heading"
            size={isLargeFromViewport ? 'medium' : 'small1x'}
          >
            {title}
          </Heading>
        </div>
        <div className={styles.trustBuilders}>
          {trustBuilders?.map((trustBuilder) => (
            <div className={styles.trustBuilderWrapper} key={trustBuilder}>
              <IconCheck />
              <div className={styles.trustBuilder}>{trustBuilder}</div>
            </div>
          ))}
        </div>
      </div>
      <ImageWithFallback
        alt="Picture of a trusted pet sitter"
        fallback={`${publicRuntimeConfig.staticPath}/images/ServiceLandingPages/TrustedSitters/TrustBuilding.jpg`}
        loading="lazy"
        webpSrc={`${publicRuntimeConfig.staticPath}/images/ServiceLandingPages/TrustedSitters/TrustBuilding.webp`}
      />
    </section>
  );
};
