import { Button } from '@madpaws/design-system';
import getConfig from 'next/config';
import React from 'react';

import { ImageWithFallback } from '~/common/components/ImageWithFallback/ImageWithFallback';
import { trackEvent } from '~/components/analytics/analytics';
import { OTHER_BRAND_CROSS_SELL_CLICK } from '~/components/analytics/constants';
import { viewPageNameResolver } from '~/components/analytics/utils';

import styles from './PetStore.module.css';
import { PET_STORE_LINK } from './constants';

import type { ReactElement } from 'react';

const { publicRuntimeConfig } = getConfig();

/*
 * This HTML ID-s are used to find the elements
 * and this is required to inject CSS from ReactNative app WebView
 * TODO(WEB-2258): Rename this variable to a more meaningful name
 */
const PET_STORE_HTML_ID = 'Explore_more_product_services';

export const PetStore = (): ReactElement => {
  const handleClickedEvent = (): void => {
    const originalUrl = new URL(window.location.href);
    const pathName = originalUrl.pathname;

    const payload = {
      link: PET_STORE_LINK,
      page: viewPageNameResolver(pathName),
      link_name: 'Mad Paws Pet Store',
    };

    trackEvent(OTHER_BRAND_CROSS_SELL_CLICK, payload);
  };

  return (
    <div className={styles.root} id={PET_STORE_HTML_ID}>
      <div className={styles.details}>
        <div className={styles.content}>
          <div className={styles.tag}>
            <p>New</p>
          </div>

          <ImageWithFallback
            alt="Picture of dog walker walking with two dogs"
            fallback={`${publicRuntimeConfig.staticPath}/images/homepage/petStoreLogo.png`}
            height={53}
            loading="eager"
            webpSrc={`${publicRuntimeConfig.staticPath}/images/homepage/petStoreLogo.webp`}
            width={190}
          />
        </div>

        <p>Fetch great savings on everything for happy, healthy pets</p>

        <a href={PET_STORE_LINK} rel="noreferrer" target="_blank">
          <Button label="Visit our Pet Store" onClick={handleClickedEvent} size="small1x" />
        </a>
      </div>

      <div className={styles.image}>
        <ImageWithFallback
          alt="Picture of dog walker walking with two dogs"
          fallback={`${publicRuntimeConfig.staticPath}/images/homepage/petStore.jpg`}
          height={244}
          loading="eager"
          webpSrc={`${publicRuntimeConfig.staticPath}/images/homepage/petStore.webp`}
          width={516}
        />
      </div>
    </div>
  );
};
