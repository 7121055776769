import { IconMoon } from '@madpaws/design-system';
import React from 'react';

import styles from './Overnight.module.css';

export const Overnight = (): React.ReactElement => (
  <div className={styles.root}>
    <IconMoon size="small2x" />
    Overnight
  </div>
);
