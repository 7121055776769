export const FAQS = [
  {
    question: 'Can you trust Pet Sitters?',
    answer: [
      'At Mad Paws, we know it can be hard to entrust your pet’s welfare to someone else. After all, they’re not just a dog, cat, puppy, rabbit, guinea pig, or bird – they’re a part of your family. It’s for this reason that Mad Paws takes Pet Sitting so seriously.',
      'All of our Pet Sitters undergo a thorough vetting procedure before they appear on Mad Paws. In fact, of all our Pet Sitting applicants, only one in four are accepted to become Mad Paws Pet Sitters. If you’re still not sure about a Pet Sitter, you can also read reviews from previous clients. By doing so, you can learn more about their pet care style from other Pet Owners.',
      'Our quest to give you peace of mind doesn’t stop there. Every Mad Paws booking is also protected by Mad Paws Accident Cover. This means that, in the event of an emergency, you can depend on Accident Cover to protect your pet. Our Customer Experience Team are also available seven days a week and can assist you at any time.',
      'Finally, when you book with Mad Paws Pet Sitting, your Pet Sitter doesn’t get paid until you confirm the end of stay. This gives you the opportunity to first assure that your pet is secure and happy before the Sitter receives payment.',
    ],
  },
  {
    question: 'What can you expect from a Mad Paws Pet Sitter?',
    answer: [
      'Of course, you don’t just want someone who is going to keep your pet safe. Rather, you want an experienced pet lover who will pamper your furry friend while you’re away. That’s why Mad Paws expects the very best from our Pet Sitters.',
      'When you book a Pet Sitter through Mad Paws, you’re not booking an impersonal dog boarding kennel or cattery. You’re leaving your furry friend in the hands of a certified, verified, bona fide pet lover. Instead of simply passing the time until you return, your pet will feel like they’re on a holiday themselves. Unlike many kennels and pet hotels, Mad Paws’ Pet Sitting prices are also very affordable. In other words, your pet will enjoy a higher quality of care for a lower price!',
      'Our Pet Sitters also meet the needs of both you and your pet. Do you have a cat who needs to maintain their schedule and remain in their natural environment? You can book a Pet Minder to stay in your home and perform Pet House Sitting duties. Do you have a puppy that loves exploring new spaces, and needs lots of exercise? A Pet Sitter can host your pooch in their home, and give them all the Dog Walking they need. We know each pet is unique, which is why you can customise your Pet Sitting package.',
    ],
  },
  {
    question: 'Can you meet a Pet Sitter before making a booking?',
    answer: [
      'Once you’ve found a Pet Sitting candidate through Mad Paws, you can arrange a Meet & Greet. This gives you a forum to meet your potential Pet Minder in person and ensure they’re perfect for your animal. Just as integrally, your pet will have the chance to “interview” the human who might be caring for them! Still not 100%? Why not book your pet in for an overnight trial stay with their Pet Sitter?',
      'After the Meet & Greet, if you decide the Pet Sitter is ideal for your furbaby, you can confirm the booking. While you’re away, your Pet Boarding pro will keep you updated with photos and messages.',
      'From Sydney to Perth, from Brisbane to Melbourne, you’ll find the best in Australian Pet Sitting with Mad Paws. Contact a Mad Paws Pet Sitter today and give your furry friend a top-tier Pet Boarding experience!',
    ],
  },
  {
    question: 'What is Pet Sitting?',
    answer: [
      'Pet Sitting is a service through which a pet care professional minds your dog, cat, puppy, bird, or other pet. Depending on the preferences of the Pet Owner, Pet Sitting can occur either at the Owner’s or the Sitter’s house. In the former case, Pet Sitters can offer Pet House Sitting, by which they mind your home and your furbaby.',
      'Pet Sitting can span for as long as the Pet Owner needs, from overnight to months at a time. Pet Sitting is also sometimes known as Dog Boarding, Cat Boarding, or Pet Boarding.',
      'Wondering how to find a Pet Sitter? Simply search your local suburb or area for pet care professionals using Mad Paws’ search widget. Once you’ve found the right Pet Minder, you can create a Mad Paws account and contact the Sitter in question.',
    ],
  },
  {
    question: 'How much is Pet Sitting per hour? How much to pay a Pet Sitter overnight?',
    answer: [
      'Pet Sitting prices can vary between Pet Sitters. Each Pet Sitter sets their own fees, with some prices being as low as $16 for an overnight stay. For the Pet Sitting option that meets your budget, browse the list of Pet Sitters in your area.',
    ],
  },
  {
    question: 'How to become a Pet Sitter? How to make money Pet Sitting?',
    answer: [
      'Are you interested in knowing how to become a certified Pet Sitter? To begin the process, all you need to do is create a Mad Paws account. You can do this by clicking “Become a Sitter” in the top right corner of the home page.',
      'From there, we will guide you through our online training program and four-step verification process. After you’ve successfully completed this, you’ll be able to create a Pet Sitting profile, which will allow you to offer Pet House Sitting, Dog Boarding, Dog Walking, Pet Day Care, and many other services.',
      'As a Pet Sitting professional, you will choose which services you offer. You can also decide whether you want to care for pets in your home or the pet’s home. Finally, you get to choose how much you charge for your Pet Sitting prices.',
      'When your profile is ready, you can start accepting bookings and making money!',
    ],
  },
  {
    question: 'Do Pet Sitters need Accident Cover?',
    answer: [
      'Mad Paws covers all bookings made through our platform with Mad Paws Accident Cover. It covers the pet being cared for in the event of an expected problem. Should a Pet Sitter wish to cover themselves, their homes, or their own pets, we suggest they obtain additional insurance.',
    ],
  },
];
