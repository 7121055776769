import {
  Heading,
  LayoutGridContainer,
  LayoutGridItem,
  useMediumFromMediaQuery,
  useLargeFromMediaQuery,
} from '@madpaws/design-system';
import React from 'react';

import styles from './ExploreMoreServices.module.css';
import { Service as ServiceView } from './components/Service/Service';
import { SERVICES } from './constants';

import type { ReactNode } from 'react';

/*
 * This HTML ID-s are used to find the elements
 * and this is required to inject CSS from ReactNative app WebView
 */
const EXPLORE_MORE_PRODUCT_SERVICES_HTML_ID = 'Explore_more_product_services';

export const ExploreMoreServices = (): React.ReactElement => {
  const isMediumFromViewport = useMediumFromMediaQuery();
  const isLargeFromViewport = useLargeFromMediaQuery();

  const renderServices = (): ReactNode => (
    <div className={styles.services}>
      {SERVICES.map((item) => (
        <ServiceView key={`service_${item.title}`} {...item} />
      ))}
    </div>
  );

  return (
    <section className={styles.root} id={EXPLORE_MORE_PRODUCT_SERVICES_HTML_ID}>
      <LayoutGridContainer>
        <LayoutGridItem placement={{ small: [1, 13], medium: [1, 13], large: [3, 11] }}>
          <Heading
            alignment="centerAlign"
            fontFamily="heading"
            size={isLargeFromViewport ? 'large1x' : isMediumFromViewport ? 'medium' : 'small1x'}
          >
            A service for every occasion
          </Heading>
        </LayoutGridItem>

        <LayoutGridItem placement={[1, 13]}>{renderServices()}</LayoutGridItem>
      </LayoutGridContainer>
    </section>
  );
};
