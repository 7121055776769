import React from 'react';

import styles from './Service.module.css';

import type { ReactNode } from 'react';

type Props = {
  description: string;
  icon: ReactNode;
  tag?: ReactNode;
  title: string;
};

export const Service = ({ title, description, icon, tag }: Props): React.ReactElement => (
  <div className={styles.root}>
    <div className={styles.icon}>{icon}</div>
    <div>
      <div className={styles.content}>
        <p className={styles.title}>{title}</p>
        <div>{tag}</div>
      </div>
      <p className={styles.description}>{description}</p>
    </div>
  </div>
);
