import Head from 'next/head';
import React from 'react';

import { constructStructuredData } from './utils';

type Props = {
  canonicalUrl: string | null;
};

export const PageHead = ({ canonicalUrl }: Props): React.ReactElement => (
  <Head>
    <title>Pet Sitting - Find Pet Sitters &amp; Dog Boarding Services | Mad Paws</title>
    <meta
      content="Browse from over 40,000 trusted and fully insured Pet Sitters Australia-wide with Mad Paws - view profiles and book with confidence."
      name="description"
    />
    {/* see more details about canonical tags */}
    {/* here: https://ahrefs.com/blog/canonical-tags/ */}
    {canonicalUrl && <link href={canonicalUrl} rel="canonical" />}
    {/* specific meta tag containing domain verification code for Facebook */}
    {/* see more details here: https://developers.facebook.com/docs/sharing/domain-verification/verifying-your-domain */}
    <meta content="azcbz52u8mqkzoh8r8ef5nc58qkwbm" name="facebook-domain-verification" />
    {/* Structured Data for SEO purposes */}
    {/* see more details here: https://developers.google.com/search/docs/appearance/structured-data/intro-structured-data */}
    <script
      type="application/ld+json"
      // eslint-disable-next-line react/jsx-sort-props, react/no-danger
      dangerouslySetInnerHTML={{ __html: constructStructuredData() }}
    />
  </Head>
);
